<template>
  <v-card flat tile :loading="loading">
    <v-card-title class="pl-2">{{ $vuetify.lang.t('Thank You') }}</v-card-title>

    <v-card-text>{{ $vuetify.lang.t('We are grateful for you choosing our services.') }}</v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="ma-2 white--text font-weight-medium"
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
        @click.stop="onConfirm"
      >
        {{ $vuetify.lang.t('Go to FS Cloud Baseball Portal') }}
        <template v-slot:loader>
          <v-progress-circular indeterminate size="24"></v-progress-circular>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Client } from '@/services';
import { Services } from '@/store/actions/api';
import { roleCheckLoop } from '../tools/helpers';
import { jsonToFormData } from '../../../utils/http-common';
import { levels } from '../../service-plan/tools/enums';
import { captureException } from '@sentry/vue';

export default {
  name: 'FreeConfirmation',

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async onConfirm(e) {
      this.loading = true;

      const params = {
        service: Services.Subscription.key,
        method: Services.Subscription.methods.NewTransaction.key,
        gateway: 'stripe',
      };

      // TODO: Use enum instead hardcoded level value
      const payload = {
        recurringPlan: levels.LEVEL0,
        payment_method_id: 0,
        email: this.$store.state.user.profile.Email,
        name: this.$store.state.user.profile.DisplayName,
      };

      const formDataPayload = jsonToFormData(payload);

      try {
        const { data: newTransactionResponse } = await Client({
          method: Services.Subscription.methods.NewTransaction.type,
          params,
          data: formDataPayload,
        });

        const { errors, response } = newTransactionResponse;

        if (!errors?.length && response) {
          if (await roleCheckLoop(this, levels.LEVEL0)) {
            this.$router.push(process.env.VUE_APP_DEFAULT_REDIRECT);
          }
        }
      } catch (error) {
        captureException(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
